@keyframes floatingToggle {
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes jetpackSmallScreen {
  0% {
    opacity: 1;
    height: 84px;
    transform: translateX(0);
  }

  50% {
    opacity: .9;
    height: 60px;
    transform: translateX(-3px);
  }

  100% {
    opacity: 1;
    height: 84px;
    transform: translateX(0);
  }
}

@keyframes jetpack {
  0% {
    opacity: 1;
    height: 126.5px;
    transform: translateX(0);
  }

  50% {
    opacity: .9;
    height: 100px;
    transform: translateX(-3px);
  }

  100% {
    opacity: 1;
    height: 126.5px;
    transform: translateX(0);
  }
}

.error-page {
  min-height: 100vh;
  color: var(--lp-color-white-950);
  background-color: #212121f7;
  background-image: linear-gradient(#212121f7 15.47%, #28282873 52.79%, #212121f7 86.92%), url("https://static.launchdarkly.com/app/s/img/error_404_page.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 1.125rem;
  line-height: 1.5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

[data-theme="legacy"] .error-page {
  background: #0e1932;
}

.error-page header, .error-page main {
  width: 40em;
}

.error-page header {
  padding: 3.75rem;
}

.error-page header a img {
  width: 12.5rem;
}

.error-page main {
  padding: 1.25rem;
  position: absolute;
  top: 10rem;
  left: 20.625rem;
}

.error-page main h1 {
  font-size: var(--lp-font-size-500);
  color: var(--lp-color-white-950);
  margin: 3.125rem 0 1.875rem;
}

.error-page a, .error-page a:link {
  color: var(--lp-color-blue-400);
  text-decoration: underline;
}

[data-theme="legacy"] .error-page a, [data-theme="legacy"] .error-page a:link {
  color: var(--lp-color-text-feedback-info);
}

.error-page a:hover, .error-page a:active {
  color: var(--lp-color-white-950);
  text-decoration: none;
}

.error-page main footer {
  padding-top: 20px;
  font-size: 15px;
}

.error-page code {
  color: #eaf3fb99;
  background-color: #0000;
}

h5.copy {
  margin-top: 20px;
}

img.error-page-logo {
  filter: invert();
}

[data-theme="legacy"] .error-page-logo {
  filter: none;
}

.no-access {
  color: var(--lp-color-white-950);
  background-color: #212121f7;
  background-image: url("https://static.launchdarkly.com/app/s/img/no-access-grid.png");
  background-position: 50% calc(50% + 47px);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  font-size: 1.125rem;
  line-height: 1.5;
}

.no-access-header {
  padding: 3.75rem 3.75rem 3.4375rem;
}

.no-access-logo img {
  filter: invert();
  width: 12.5rem;
}

.no-access-starburst {
  width: 302px;
  height: 539px;
  z-index: var(--stacking-above-new-context);
  background-image: url("https://static.launchdarkly.com/app/s/img/no-access-starburst.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 10%;
  right: 0;
}

.no-access-main {
  backdrop-filter: blur(28px);
  z-index: calc(var(--stacking-above-new-context)  + 1);
  background: #58595b1a;
  position: relative;
}

.no-access-main h1 {
  font-size: var(--lp-font-size-500);
  margin: 3.125rem 0 1.25rem;
  line-height: 1.133;
}

.no-access-content {
  max-width: 37.5rem;
  margin: auto;
  padding: 2.5rem 1.25rem 6.25rem;

  @media screen and (width >= 62rem) {
    & {
      margin-left: 20.625rem;
    }
  }
}

.no-access a, .no-access a:link {
  color: var(--lp-color-blue-400);
}

.no-access a:hover, .no-access a:active {
  color: var(--lp-color-white-950);
}

.error-404-page {
  background-color: #212121f7;
  background-image: url("https://static.launchdarkly.com/app/s/img/not-found/noise.png");
  background-repeat: repeat;
  min-height: 100vh;
  overflow: hidden;

  & header {
    width: 100%;
    max-width: 71.25rem;
    margin: 0 auto;
    padding: 3.125rem 1.25rem;
  }

  & header a img {
    width: 100%;
    max-width: 12.5rem;
  }

  & a.ld-error-logo {
    & div {
      border-radius: 2px;
      width: 100%;
      max-width: 12.5rem;
    }

    &:focus {
      box-shadow: none;
      outline: none;

      & div {
        box-shadow: var(--link-box-shadow-focus);
        outline: none;
      }
    }

    &:focus:not(:focus-visible) {
      outline: none;

      & div {
        box-shadow: none;
      }
    }

    &:focus-visible {
      & div {
        box-shadow: var(--link-box-shadow-focus);
      }
    }
  }

  & a {
    color: var(--lp-color-gray-300);
  }

  & a[class*="_Button"] {
    background-color: var(--lp-color-bg-interactive-primary-base);
    color: var(--lp-color-white-950);
    cursor: pointer;
    font-family: var(--lp-font-family-base);
    font-size: var(--lp-font-size-300);
    white-space: nowrap;
    border: none;
    border-radius: 0;
    padding: .6875rem 1.375rem;
    line-height: 1.5em;
    text-decoration: none;
    transition: background-color .2s ease-in-out;
    display: inline-flex;

    &:hover {
      background-color: var(--lp-color-blue-600);
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--lp-color-gray-900), 0 0 0 4px var(--lp-color-blue-500);
    }

    &:focus:not(:focus-visible) {
      box-shadow: unset;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px var(--lp-color-gray-900), 0 0 0 4px var(--lp-color-blue-500);
    }
  }

  & .container {
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    gap: 0;
    width: 100%;
    max-width: 71.25rem;
    margin: 0 auto;
    padding: 0 .78125rem;
    display: grid;
    position: relative;

    @media screen and (width >= 62rem) {
      & {
        grid-template-columns: 32.5rem 1fr;
      }
    }
  }

  & .content {
    --text-gradient-a: #edf4c9;
    --text-gradient-b: #9eadf1;
    text-align: center;
    z-index: var(--stacking-z-index-10);
    align-self: center;
    padding: 2.5rem 0;
    position: relative;

    @media screen and (width >= 62rem) {
      & {
        text-align: left;
        padding: 0;
      }
    }

    & h1.gradientText {
      font-family: "Audimat 3000", var(--lp-font-family-base);
      font-size: var(--lp-font-size-800);
      background-color: var(--text-gradient-b);
      background-image: linear-gradient(90deg, var(--text-gradient-a) 0%, var(--text-gradient-b) 100%);
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      margin-bottom: .25rem;
      display: inline-block;
    }

    & p {
      color: var(--lp-color-gray-300);
      font-weight: 400;
      font-size: var(--lp-font-size-500);
      margin-bottom: 1.875rem;
      line-height: 1.95rem;
    }
  }

  & .images {
    position: relative;
    right: 0;

    @media screen and (width >= 62rem) {
      & {
        min-height: 40rem;
        display: block;
        right: -.78125rem;
      }
    }

    & .toggle {
      width: 100%;
      max-width: 18.75rem;
      margin: auto;
      display: grid;
      position: relative;

      @media screen and (width >= 62rem) {
        & {
          z-index: var(--stacking-above-new-context);
          max-width: 25rem;
          display: block;
          position: absolute;
          bottom: 10.3125rem;
          right: 4.6875rem;
        }
      }

      & .toggleBody {
        vertical-align: top;
        z-index: calc(var(--stacking-above-new-context)  + 1);
        position: relative;
      }

      & .jet {
        object-fit: cover;
        width: 40px;
        height: auto;
        position: absolute;

        @media screen and (width >= 62rem) {
          & {
            width: 60px;
          }
        }
      }

      & .jetOne {
        z-index: var(--stacking-above-new-context);
        top: 238px;
        left: 96px;

        @media screen and (width >= 62rem) {
          & {
            top: 318px;
            left: 126px;
          }
        }
      }

      & .jetTwo {
        z-index: var(--stacking-above-new-context);
        animation-delay: .2s;
        top: 233px;
        left: 123px;

        @media screen and (width >= 62rem) {
          & {
            top: 309px;
            left: 160px;
          }
        }
      }
    }
  }

  & .floating-toggle {
    animation-name: floatingToggle;
    animation-duration: 5s;
    animation-timing-function: cubic-bezier(.4, .01, .6, 1);
    animation-iteration-count: infinite;
  }

  & .jet-flame-animation {
    animation-name: jetpackSmallScreen;
    animation-duration: .6s;
    animation-timing-function: cubic-bezier(0, .3, 1, .7);
    animation-iteration-count: infinite;

    @media screen and (width >= 62rem) {
      & {
        animation-name: jetpack;
      }
    }
  }

  & .darkStar {
    display: none;

    @media screen and (width >= 62rem) {
      & {
        z-index: var(--stacking-above-new-context);
        width: 5.3125rem;
        display: block;
        position: absolute;
        bottom: 6.25rem;
        left: 40%;
      }
    }
  }

  & .darkStarSmall {
    display: none;

    @media screen and (width >= 62rem) {
      & {
        z-index: var(--stacking-above-new-context);
        opacity: .7;
        width: 4.25rem;
        display: block;
        position: absolute;
        top: -2.5rem;
        left: 50%;
      }
    }
  }

  & .darkStarLong {
    display: none;

    @media screen and (width >= 62rem) {
      & {
        z-index: var(--stacking-above-new-context);
        width: 8.125rem;
        display: block;
        position: absolute;
        top: 8.125rem;
        left: -1.25rem;
      }
    }
  }

  & .planet {
    width: auto;
    max-width: 9.375rem;
    height: auto;
    display: block;
    position: absolute;
    top: -5.4375rem;
    right: 0;
    transform: rotate(60deg);

    @media screen and (width >= 62rem) {
      & {
        max-width: 18.9063rem;
      }
    }
  }

  & .planetSmall {
    width: 100%;
    max-width: 9.375rem;
    height: auto;
    display: none;

    @media screen and (width >= 62rem) {
      & {
        display: inline-block;
        position: absolute;
        bottom: -3.125rem;
        left: 1rem;
        transform: rotate(45deg);
      }
    }
  }

  & .stars {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

:root[data-type-refresh] {
  & .error-page main h1, & .no-access-main h1 {
    font: var(--lp-text-heading-1-semibold);
  }

  & .error-404-page {
    & a[class*="_Button"] {
      font: var(--lp-text-body-1-regular);
    }

    & .content {
      & h1.gradientText {
        font: var(--lp-text-display-1);
      }
    }
  }
}
